<template>
  <div :style="'width:' + width + '; height:' + height + ';'" :class="['image', {'image--circle': circle}, 
																																								{'image--border': border}, 
																																								{'image--align-top': align === 'top'}]">
		<img class="image__image" :src="require('@/assets/images/' + image + '')" v-if="image" />
	</div>
</template>

<script>
export default {
  props: {
		image: String,
    height: String,
		width: String,
		padding: String,
		circle: Boolean,
		border: Boolean,
		align: String,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.image{
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
}
.image__image{
	object-fit: cover;
	min-height: 100%;
	min-width: 100%;
	object-position: center top;
}
.image--align-top{
	align-items: flex-start;
}


.image--circle{
	border-radius: 50%;
}
.image--border{
	border: var(--image-border)
}


</style>

<!-- Used in : AR, DS -->